// DataTable.js
import React from 'react';
import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const DataTable = ({ data, currentNum, isLoading,numberOfData,sendInvoices }) => {
  return (
    <>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Receipt No</TableCell>
          <TableCell>Registration No</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Contact No</TableCell>
            <TableCell>Student Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Installment</TableCell>
            <TableCell>No. of Installments</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Total Fee</TableCell>
            <TableCell>Net Total</TableCell>
            <TableCell>Tax Total</TableCell>
            <TableCell>Total with Tax</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} style={{ backgroundColor: row.status === 'success' ? 'lightgreen' : row.status === 'error' ? 'lightcoral' : 'white' }}>
               <TableCell>{row.receipt_no}</TableCell>
               <TableCell>{row.registration_no}</TableCell>
               <TableCell>{row.email}</TableCell>
               <TableCell>{row.contact_no}</TableCell>
              <TableCell>{row.student_name}</TableCell>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.installment}</TableCell>
              <TableCell>{row.no_of_installment}</TableCell>
              <TableCell>{row.discount}</TableCell>
              <TableCell>{row.total_fee}</TableCell>
              <TableCell>{row.net_total}</TableCell>
              <TableCell>{row.tax_total}</TableCell>
              <TableCell>{row.total_with_tax}</TableCell>
              <TableCell>
  {!isLoading ? (
    row.status === 'success' ? (
      <Typography style={{ color: 'green' }}>Success</Typography>
    ) : (
        row.status === 'error' ?(
            <Typography style={{ color: '#3d010b' }}>Error</Typography>
        ):(
            <Typography style={{ color: 'blue' }}>Ready</Typography>
        )
      
    )
  ) : (
    row.status === 'success' ? (
        <Typography style={{ color: 'green' }}>Success</Typography>
      ) : (
          row.status === 'error' ?(
              <Typography style={{ color: '#3d010b' }}>Error</Typography>
          ):(
            <CircularProgress sx={{ color: 'grey.500' }} />
        )
        
      )
  )}
</TableCell>            </TableRow>
          ))}
        </TableBody>
      </Table>
     
    </TableContainer>
    <br />
    {isLoading? <p>Sending {currentNum} of {numberOfData} receipts...</p> : <Button variant="contained" color="success" onClick={sendInvoices}>
     Send Receipts
   </Button>}
     
    </>
  );
};

export default DataTable;
