import React, { useState } from 'react';
import { Container, Typography, Box, Paper, Grid, Button } from '@mui/material';
import { UploadExcel } from './components/UploadExcel';
import { Invoice } from './components/Invoice';
import DataTable from './components/DataTable';
import axios from 'axios';

import logo from './assets/logo.png'; // Import your logo
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function App() {
  const [data, setData] = useState([]);
  const [number, setNumber] = useState(0);
  const [numberOfData, setNumberOfData] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const [progress, setProgress] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleGetStarted = () => {
    setShowUpload(true);
  };

  const sendInvoices = async () => {
    const confirmed = window.confirm('Are you sure you want to send these receipts?');
    if (!confirmed) {
      return; // Exit function if user cancels
    }
  
    setIsLoading(true);
    const updatedData = [...data];
    setNumberOfData(updatedData.length);
  
    for (let i = 0; i < updatedData.length; i++) {
      const entry = updatedData[i];
      const postData = {
        to:  entry.email,
        subject: 'Your SLTC Receipt',
        text: `Dear ${entry.student_name},\n\nAttached is your SLTC Receipt for the semester fees. Please review and ensure payment by the due date.\nFor any inquiries, contact us at tandf@sltc.ac.lk.\n\nThank you,\nSLTC Finance Team`,
        student_name: entry.student_name,
        contact_no: entry.contact_no,
        registration_no: entry.registration_no,
        receipt_no: entry.receipt_no,
        date: entry.date,
        address: entry.address,
        code: entry.code,
        description: entry.description,
        installment: entry.installment,
        no_of_installment: entry.no_of_installment,
        discount: entry.discount,
        total_fee: entry.total_fee,
        net_total: entry.net_total,
        tax_total: entry.tax_total,
        total_with_tax: entry.total_with_tax,
      };
  
      try {
        await axios.post('https://apireceipt.c3e.tech/send-email', postData);
        updatedData[i].status = 'success';
      } catch (error) {
        updatedData[i].status = 'error';
      }
      
      setNumber(i + 1); // Update the current number of invoices sent
      setData(updatedData); // Update the state to re-render the table
    }
  
    setIsLoading(false);
  };
  

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#151c3d', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 1300, width: '100%', textAlign: 'center', backgroundColor: '#fff', borderRadius: 2 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Box
              component="img"
              src={logo}
              alt="Receipt"
              width="250px"
              borderRadius={2}
            />
          </Grid>
          <Grid item xs={12}>
          {isLoading && 
          <LinearProgress  sx={{mb:5}}/>
          }
          {data.length > 0 && 
          <>
          <Typography variant="h3" gutterBottom>
          Receipt List
          </Typography>
          <Typography variant="body1" gutterBottom>
              Please check if all the fields are completed before sending the receipts.
            </Typography>
          </>
          }




          {!(data.length > 0) &&  
          
          <>
            <Typography variant="h3" gutterBottom>
              Welcome to the SLTC Receipt Generator
            </Typography>
            <br></br>   
  
            <Typography variant="body1" gutterBottom style={{paddingLeft:150,paddingRight:150}}>
              This application is designed for the Sri Lanka Telecommunication Campus (SLTC) to streamline the reception process.
              Upload your Excel file containing student data, generate detailed HTML receipts, and send them in bulk via email.
              Start now to simplify and automate your reception tasks!
            </Typography>
            </>
          
          }
          
            <br></br>
           
              <>
              {!(data.length > 0) &&  <UploadExcel setData={setData} />}

               
                {/* {data.length > 0 && <Invoice data={data} />} */}
                {data.length > 0 && <DataTable data={data} currentNum={number} isLoading={isLoading} numberOfData={numberOfData} sendInvoices={sendInvoices} />}

                {/* <LinearProgressWithLabel value={progress} /> */}

              </>


            
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default App;
