// UploadExcel.js
import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';

export const UploadExcel = ({ setData }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setData(jsonData.map(row => ({ ...row, 
        
        installment: parseFloat(row.installment).toFixed(2),
    discount: parseFloat(row.discount).toFixed(2),
    total_fee: parseFloat(row.total_fee).toFixed(2),
    net_total: parseFloat(row.net_total).toFixed(2),
    tax_total: parseFloat(row.tax_total).toFixed(2),
    total_with_tax: parseFloat(row.total_with_tax).toFixed(2),
        status: '' }))); // Initialize status field
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
    <br></br>    
    

      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="upload-file"
      />
      <label htmlFor="upload-file">
        <Button variant="contained" component="span">
          Upload Excel
        </Button>
      </label>
    </div>
  );
};
